import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/gt-housing/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const GTHousing = ({ data, path }) => {

    return (
        <Project
            title="Georgia Tech Student Housing"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                The Georgia Tech Student Housing project was a major rebranding 
                and development effort to improve the way students find housing. 
                The old platform needed a fresh look and better functionality, 
                so we revamped the entire experience—from design to performance.
                </p>
                <p>
                We built the site on WordPress and integrated Algolia to 
                power fast and accurate search results, 
                making it easier for students to find what they need. 
                To add a layer of interactivity, we used GSAP animations, 
                bringing the interface to life with smooth, engaging transitions.
                </p>
                <p>
                We structured 130 content pages to make every detail about student housing 
                easily accessible and well-organized, creating a seamless 
                and intuitive platform that simplifies the housing search for students.
                </p>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('gtH')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('gtH')} />
                </div>
                <FeatureCreator projectData={projects.get('gtH')} />
             </Content>
        </Project>
    )
}


GTHousing.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default GTHousing
